
// jQuery(document).ready(function() {
module.exports = () => {

    jQuery(window).load(function() {
        
        // Register GSAP plugins
        gsap.registerPlugin(ScrollSmoother, ScrollTrigger, SplitText, CustomEase);

        

        // Skew on scroll effect
        let skewSetter = gsap.quickTo('.skew-on-scroll', 'skewY');
        let skewReverseSetter = gsap.quickTo('.skew-on-scroll-reverse', 'skewY');
        let skewClamp = gsap.utils.clamp(-5, 5); // min and max values skew can have


        // scroll smoother
        let smoother = ScrollSmoother.create({
            smooth: .2, 
            smoothTouch: 0, // off for touch screen
            effects: true,
            onUpdate: self => {
                skewSetter(skewClamp(self.getVelocity() / 100));
                skewReverseSetter(skewClamp(- self.getVelocity() / 100));
            },
            onStop: () => skewSetter(0)
        });

        setTimeout(() => {
            smoother.refresh();
        }, 500)



        // mouse scroller
        let mouse = document.querySelector('.mouse');
        if (mouse) {
            mouse.addEventListener('click', function() {
                console.log('clicked');
                let target = document.querySelector('.section-id-1');
                gsap.to(smoother, {
                    scrollTop: smoother.offset(target),
                    duration: .6,
                    delay: 0
                })
            });
            
        }


        // for anchor tags
        gsap.utils.toArray('a[href*="#"]').forEach(el => {
            el.addEventListener('click', function(event) {
                event.preventDefault();

                let ids = this.getAttribute('href').split('#');
                let id = ids[ids.length - 1];
                let scrollTo = document.getElementById(id)
                gsap.to(smoother, {
                    scrollTop: smoother.offset(scrollTo, "top top+=100px"),
                    duration: 1,
                  });
            })
        });

        let urlHash = window.location.href.split("#")[1];
        let scrollElem = document.querySelector("#" + urlHash);
      
      
        if (urlHash && scrollElem) {
          gsap.to(smoother, {
            scrollTop: smoother.offset(scrollElem, "top top+=100px"),
            duration: 1,
            delay: 0
          });
        }
    
    
    
        // parallax effect
        gsap.utils.toArray('.parallax img').forEach(el => {
            let speed = el.dataset.speed || 'auto';
            smoother.effects(el, {
                speed: speed
            });
        });

        
    
        // FADE INS
        gsap.utils.toArray('*[class^="fade-from-"]').forEach(el => {
            let target = el;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(el, {type: 'lines,words'});
                target = splitText.words;
            } else if (split === 'lines') {
                let splitInitial = new SplitText(el, {type: 'lines'});
                splitText = new SplitText(splitInitial.lines, {type: 'lines'});
                target = splitText.lines;
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center bottom';
            let end = el.dataset.end || 'center center';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;
            gsap.set(target, {skewY: skewy});

            let skewx = el.dataset.skewx || 0;
            gsap.set(target, {skewX: skewx});

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('fade-from-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }
            gsap.set(target, { [side] : amount });

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                opacity: '1',
                skewY: '0',
                skewX: '0',
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });





        let sticky = document.querySelector('.our_services .sticky');
        if (sticky) {
            ScrollTrigger.create({
                trigger: sticky,
                start: 'top top',
                end: "+=" + (document.querySelector('.our_services').offsetHeight - sticky.offsetHeight),
                pin: true,
                scrub: true,
                anticipatePin: 1
            })
        }

        

        gsap.utils.toArray('.our_services .service-single').forEach(el => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top center',
                end: 'bottom center',
                onEnter: function(el) {
                    let currentlyActive = sticky.querySelector('.service-list .active');
                    let id = el.trigger.querySelector('.title').id;
                    console.log(id);
                    currentlyActive.classList.remove('active');
                    document.querySelector(`.service-list a[href="#${id}"]`).classList.add('active');
                },
                onEnterBack: function(el) {
                    console.log(el.trigger, 'onenterback')
                    let currentlyActive = sticky.querySelector('.service-list .active');
                    let id = el.trigger.querySelector('.title').id;
                    currentlyActive.classList.remove('active');
                    document.querySelector(`.service-list a[href="#${id}"]`).classList.add('active');
                }
            })
        });

        
        // Fade out animations
        gsap.utils.toArray('*[class^="fade-to-"]').forEach(el => {
            let target = el;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(el, {type: 'lines,words'});
                target = splitText.words;
            } else if (split === 'lines') {
                let splitInitial = new SplitText(el, {type: 'lines'});
                splitText = new SplitText(splitInitial.lines, {type: 'lines'});
                target = splitText.lines;
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center center';
            let end = el.dataset.end || 'center top';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;

            let skewx = el.dataset.skewx || 0;

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('fade-to-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                [side] : amount,
                opacity: '0',
                skewY: skewy,
                skewX: skewx,
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });






        // REVEAL INS
        gsap.utils.toArray('*[class^="reveal-from-"]').forEach(el => {
            let target = el.children;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(target, {type: split});
                target = splitText[split];
            } else if (split === 'lines') {
                splitInitial = new SplitText(target, {type: split});
                splitText = new SplitText(target, {type: split});
                target = splitInitial[split];
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center bottom';
            let end = el.dataset.end || 'center center';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;
            gsap.set(target, {skewY: skewy});

            let skewx = el.dataset.skewx || 0;
            gsap.set(target, {skewX: skewx});

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('reveal-from-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }
            gsap.set(target, { [side] : amount });

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                skewY: '0',
                skewX: '0',
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });


        // REVEAL OUTS
        gsap.utils.toArray('*[class^="reveal-to-"]').forEach(el => {
            let target = el.children;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(target, {type: split});
                target = splitText[split];
            } else if (split === 'lines') {
                splitInitial = new SplitText(target, {type: split});
                splitText = new SplitText(target, {type: split});
                target = splitInitial[split];
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center center';
            let end = el.dataset.end || 'center top';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;

            let skewx = el.dataset.skewx || 0;

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('reveal-to-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                [side] : amount,
                opacity: '1',
                skewY: skewy,
                skewX: skewx,
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });




        

        // MOVE INS
        gsap.utils.toArray('*[class^="move-from-"]').forEach(el => {
            let target = el;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(el, {type: 'lines,words'});
                target = splitText.words;
            } else if (split === 'lines') {
                let splitInitial = new SplitText(el, {type: 'lines'});
                splitText = new SplitText(splitInitial.lines, {type: 'lines'});
                target = splitText.lines;
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center bottom';
            let end = el.dataset.end || 'center center';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;
            gsap.set(target, {skewY: skewy});

            let skewx = el.dataset.skewx || 0;
            gsap.set(target, {skewX: skewx});

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('move-from-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }
            gsap.set(target, { [side] : amount });

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                skewY: '0',
                skewX: '0',
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });
        // MOVE INS
        gsap.utils.toArray('*[class^="move-to-"]').forEach(el => {
            let target = el;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(el, {type: 'lines,words'});
                target = splitText.words;
            } else if (split === 'lines') {
                let splitInitial = new SplitText(el, {type: 'lines'});
                splitText = new SplitText(splitInitial.lines, {type: 'lines'});
                target = splitText.lines;
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center center';
            let end = el.dataset.end || 'center top';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;

            let skewx = el.dataset.skewx || 0;

            let amount = el.dataset.amount || '75px';
            let side = Array.from(el.classList).filter(item => item.includes('move-to-'))[0].split('-').at(-1);
            if (side !== 'top') {
                amount = '-' + amount;
            } else {
                side = 'bottom';
            }

            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                [side] : amount,
                skewY: skewy,
                skewX: skewx,
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });



        // SCALE INS
        gsap.utils.toArray('.scale-in').forEach(el => {
            let target = el;
            let split = el.dataset.split;
            let splitText;
            if (split === 'words') {
                splitText = new SplitText(el, {type: 'lines,words'});
                target = splitText.words;
            } else if (split === 'lines') {
                let splitInitial = new SplitText(el, {type: 'lines'});
                splitText = new SplitText(splitInitial.lines, {type: 'lines'});
                target = splitText.lines;
            }

            let time = Number(el.dataset.time) || 1.2;
            let delay = Number(el.dataset.delay) || 0;
            let stagger = Number(el.dataset.stagger) || 0.2;

            let easing = el.dataset.easing || "power1.out";

            let from = el.dataset.from || .5;
            gsap.set(target, {scale: from});
            let to = el.dataset.to || 1;

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center bottom';
            let end = el.dataset.end || 'center center';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let skewy = el.dataset.skewy || 0;
            gsap.set(target, {skewY: skewy});

            let skewx = el.dataset.skewx || 0;
            gsap.set(target, {skewX: skewx});


            gsap.to(target, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                scale: to,
                skewY: '0',
                skewX: '0',
                delay: delay,
                stagger: stagger,
                ease: easing,
                onComplete: () => {
                    if (!scrub && splitText) {
                        splitText.revert()
                    }
                }
            });
        });

        
        
        // Cursor text
        gsap.utils.toArray('.cursor-text b:nth-of-type(1)').forEach(el => {
            // create master timeline
            let masterCursorTl = gsap.timeline({repeat: -1});
    
            // get list of words and trim any whitespace
            let words = el.dataset.words.split('|');

            let time = el.dataset.time || 1;
            let delay = el.dataset.delay || 1.3;
    
            // create tl for each word for each el
            words.forEach(word => {
                let tl = gsap.timeline({repeat: time, repeatDelay: delay, yoyo: true});
                tl.to(el, {
                    duration: time,
                    text: word,
                    ease: 'none'
                });
                masterCursorTl.add(tl);
            });
        });
        // actual cursor from cursor text
        gsap.utils.toArray('.cursor-text b:nth-of-type(2)').forEach( el => {
            gsap.from(el, {
                opacity: '0',
                repeat: -1,
                yoyo: true,
                delay: 0,
                duration: 0.3
            });
        });
        
        
    
        // rotating text
        gsap.utils.toArray('.rotating-text').forEach(rotator => {
            let targetEls = rotator.querySelectorAll('span');
            let targets = gsap.utils.toArray(targetEls);
            
            let dur = Number(rotator.dataset.time) || 0.5;
            let hold = Number(rotator.dataset.delay) || 1;
            let outerTl = gsap.timeline();
            outerTl.set(rotator, {height: targets[0].offsetHeight});

            let maxWidth = 0;
            for (let i=0; i<targets.length; i++) {
                let target = targets[i];
                let targetWidth = target.offsetWidth;
                if (targetWidth > maxWidth) {
                    maxWidth = targetWidth;
                }
            }

            gsap.set(rotator, { width: maxWidth + "px", transition: dur + 's' });

            targets.forEach((obj, i) => {
                let tl = gsap.timeline({
                    delay: dur * i + hold * i + .4,
                    repeat: -1,
                    repeatDelay: (targets.length - 1) * (dur + hold) - dur,
                    defaults: {
                        ease: CustomEase.create("custom", "M0,0 C0.202,0 0.406,0.09 0.5,0.5 0.59,0.892 0.7,1 1,1"),
                        duration: dur
                    },
                });
    
                tl.from(obj, {yPercent: -100, opacity: 0},0 );
                tl.set(obj.parentNode, {height: obj.offsetHeight - 8}, 0);
                tl.to(obj, {yPercent: 100, opacity: 0}, "+=" + hold);
            });
        });



        // number increment
        gsap.utils.toArray('.number-increment').forEach(el => {
            var Cont = { val: 0 }
            var value = Number(el.dataset.value) || 100;
            let time = Number(el.dataset.time) || 1;
            let delay = Number(el.dataset.delay) || 0;

            let scrub = el.dataset.scrub || false;
            let start = el.dataset.start || 'center bottom';
            let end = el.dataset.end || 'center center';
            let toggleAction = el.dataset.toggleaction || 'play none none none';
            let markers = el.dataset.markers || false;

            let easing = el.dataset.easing || "power1.out";
    
            gsap.to(Cont, time, {
                scrollTrigger: {
                    trigger: el,
                    scrub: scrub,
                    start: start,
                    end: end,
                    toggleAction: toggleAction,
                    markers: markers
                },
                val: value,
                roundProps: "val",
                ease: easing,
                delay: delay,
                onUpdate: function () {
                    el.innerHTML = Cont.val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
                }
            });
        })


    });

}    
