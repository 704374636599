
module.exports = () => {
    jQuery(document).ready(function() {

        // expand answer and spin arrow when clicking question
        let faqQuestions = document.querySelectorAll('.faq .question');
        for (let i=0; i<faqQuestions.length; i++) {

            let question = faqQuestions[i];
            question.addEventListener('click', function() {

                let answer = this.nextElementSibling;
                let parent = this.parentElement;

                // if closing the question
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                    let height = '0px';
                    answer.style.maxHeight = height;
                }
                // if opening the question 
                else {
                    parent.classList.add('open');
                    let height = answer.style.height = (answer.scrollHeight + 25) + 'px';
                    answer.style.maxHeight = height;
                }
            })
        }


        // Auto-expand first question on page load
        let faqLayouts = document.querySelectorAll('.faqs');
        for (let i=0; i<faqLayouts.length; i++) {
            let layout = faqLayouts[i];
            let first = layout.querySelector('.faq');
            let answer = first.querySelector('.answer');

            first.classList.add('open');
            let height = answer.style.height = (answer.scrollHeight + 25) + 'px';
            answer.style.maxHeight = height;
        }
    })
}