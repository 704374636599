module.exports = () => {
    jQuery(document).ready(function() {
        // MOBILE MENU EXPANDING/COLLAPSING 
        let hamburger = document.getElementById('hamburger');
        let mobileMenu = document.getElementById('mobile-menu');
        
        
        hamburger.onclick = function() {
            // if we are closing the menu
            if (this.classList.contains('is-active')) {
                this.classList.remove('is-active');
                mobileMenu.classList.remove('mobile-menu-open');

                document.body.style.height = document.body.dataset.prevHeight;

                // close all sub-menus
                let subMenus = mobileMenu.querySelectorAll('.sub-menu');
                for (let i=0; i<subMenus.length; i++) {
                    let subMenu = subMenus[i];
                    subMenu.classList.remove('open');
                }
            } 
            // otherwise we are opening the menu
            else {
                this.classList.add('is-active');

                document.body.dataset.prevHeight = document.body.style.height;
                mobileMenu.classList.add('mobile-menu-open');
                document.body.style.height = '100vh';
            }
        }

        // close mobile menu if clicking off menu
        document.addEventListener('click', function(e) { 
            // if (jQuery(e.target).parents(hamburger).length === 0 && jQuery(e.target).parents(mobileMenu).length === 0 && hamburger.classList.contains('is-active')) {
            if (!jQuery(hamburger).is(e.target) && jQuery(hamburger).has(e.target).length === 0 &&
            !jQuery(mobileMenu).is(e.target) && jQuery(mobileMenu).has(e.target).length === 0 &&
            hamburger.classList.contains('is-active')) {
                hamburger.classList.remove('is-active');
                mobileMenu.classList.remove('mobile-menu-open');
                document.body.style.overflow = 'visible';
                document.body.style.height = 'auto';
            }
        });


        let buttons = document.querySelectorAll('.mobile-menu .menu-item-has-children b');
        buttons.forEach((button) => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                let subMenu = this.parentNode.nextElementSibling;

                if (!subMenu.classList.contains('open')) {
                    subMenu.classList.add('open');
                }
            })
        });
        let backButtons = document.querySelectorAll('.sub-menu .back-button');
        backButtons.forEach((button) => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                let subMenu = this.parentNode.parentNode.parentNode;
                subMenu.classList.remove('open');
            })
        })




        // footer menu
        let footerDropDownArrow = document.querySelectorAll('.footer-menu .menu-item-has-children b');
        for (let i=0; i<footerDropDownArrow.length; i++) {
            let arr = footerDropDownArrow[i];
            arr.addEventListener('click', function(e) {
                e.preventDefault();
                let subMenu = this.parentNode.parentNode.querySelector('.sub-menu');
                if (subMenu.classList.contains('open')) {
                    subMenu.classList.remove('open');
                    this.style.transform = 'rotate(90deg)';
                } else {
                    subMenu.classList.add('open');
                    this.style.transform = 'rotate(270deg)';
                }
            });
        }




        // mega menu
        let firstChildren = document.querySelectorAll('#menu-mega-menu > li');

        // set initial width
        let parent = document.querySelector('.mega-menu');
        let subMenu = firstChildren[0].querySelector('.sub-menu');
        parent.style.width = (subMenu.offsetWidth + 500) + "px";

        // for every child 1 deep
        for (let i=0; i<firstChildren.length; i++) {
            let child = firstChildren[i];

            child.addEventListener('mouseenter', function() {

                // remove open from all
                for (let j=0; j<firstChildren.length; j++) {
                    firstChildren[j].classList.remove('open');
                }
                // add open class when hovering
                this.classList.add('open');

                // adjust width
                let parent = document.querySelector('.mega-menu');
                let subMenu = this.querySelector('.sub-menu');
                parent.style.width = (subMenu.offsetWidth + 500) + "px";
            });
        }


    })
}